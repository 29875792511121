<template>
  <div class="aiz-user-panel">
    
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <b class="h4">{{ $t('ding-dan-lie-biao') }}</b>
        </div>
      </div>
    </div>

    <div class="row gutters-5">
      
      <div class="col-xxl-3 col-xl-4 col-lg-3 col-md-4 col-sm-6" v-for="(item, i) in tableData" :key="i" @click="toDetail(item)">
        <div class="card mb-2 shadow-sm">
          <div class="card-body">
            <a class="d-block mb-3">
              <img :src="item.Goods.ImgUrl" class="img-fit h-140px h-md-200px">
            </a>

            <h5 class="fs-14 mb-0 lh-1-5 fw-600 text-truncate-2 mt-2 mb-2">
              <a  class="text-reset">{{item.Goods.GoodsName }}</a>
            </h5>
           
            <div class=" fs-14 flex-center-between mb-1">
              <span class="fw-600 gray">{{ $t('ding-dan-jia-ge') }}</span>
              <span class="fw-600 ">${{item.PayMoney}}</span>
            </div>
            <div class=" fs-14 flex-center-between mb-1">
              <span class="fw-600 gray">{{ $t('ding-dan-shou-yi') }}</span>
              <span class="fw-600 ">${{item.EarnCommission}}</span>
            </div>
            <div class=" fs-14 flex-center-between mb-1">
              <span class="fw-600 gray">{{ $t('ding-dan-zhuang-tai') }}</span>
              <span class="fw-600 ">{{$t(`task-status${item.Status}`)}}</span>
            </div>
          </div>
        </div>
      </div>
                
      <div class="col" v-if="tableData.length == 0">
        <div class="text-center bg-white p-4 rounded shadow">
          <img class="mw-100 h-200px" :src="emptyUrl" >
          <h5 class="mb-0 h5 mt-3">{{$t('order-empty')}}</h5>
        </div>
      </div>
    </div>
    <div class="aiz-pagination mt-4" v-show="page.total > 1">
      <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
    </div>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { taskList } from '@/api/user'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty.svg'),
      showPopup: false,
      page: {
        page: 1,
        limit: 10,
        total: 1
      },
      tableData: []
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  methods: {
    resetPrice,
    changePage(page) {
      this.page.page = page 
      this.init()
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      taskList(form).then(res => {
        this.tableData = res.data.Items.filter(v => {
          return v.Status == 1 || v.Status == 4
        })
        this.page.total = res.data.Pagination.totalPage
			})
    },
    toDetail(data) {
      sessionStorage.setItem('taskDetail', JSON.stringify(data))
      this.$router.push({
        name: 'taskDetail',
      })
    },
  }
}
</script>